<template>
  <div class="page m-page">
    <div class="main">
      <img
        src="https://directimg.miaocang.cc/mpImg/attraction/default_background@1x.png" 
        draggable="false"
        oncontextmenu="return false;"
        class="background unselectable"
      >
      <div class="title">苗仓地被直采中心</div>
      <img
        src="https://directimg.miaocang.cc/mpImg/attraction/default@2x.png"
        draggable="false"
        oncontextmenu="return false;"
        class="unselectable"
      />
    </div>
    <div class="footer m-footer">
      <van-button class="large-button" size="large" color="#00ae66" @click="toApplyPage">申请入驻</van-button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DefaultAttraction',

  methods: {
    /* 申请入驻的点击事件处理(跳转到华南项目的申请入驻H5页) */
    toApplyPage() {
      window.open('https://sc.miaocang.cc/#/mBusiness')
      // wx.miniProgram.navigateTo({
      //   url: '/pages/webView/webView?src=https://sc.miaocang.cc/#/mBusiness'
      // })
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  height: 100%;
  // background-color: #ebf9f3;
  .main {
    position: relative;
    padding: 28px 0 88px;
    .background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
    .title {
      margin: 10px 0 38px;
      text-align: center;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 1px;
      font-weight: 600;
      color: #146e4f;
    }
    img {
      width: 100%;
      object-fit: cover;
    }
    .text {
      margin-top: 28px;
      padding: 0 14px;
      text-align: left;
      font-size: 14px;
      color: #fff;
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 70px;
    padding-top: 14px;
    text-align: center;
    background-color: #fff;
    z-index: 1000;
    .half-button {
      width: 43.6%;
      &:first-child {
        margin-right: 14px;
      }
    }
    .large-button {
      width: 92%;
    }
  }
}
</style>